exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-models-js": () => import("./../../../src/pages/models.js" /* webpackChunkName: "component---src-pages-models-js" */),
  "component---src-pages-routes-js": () => import("./../../../src/pages/routes.js" /* webpackChunkName: "component---src-pages-routes-js" */),
  "component---src-pages-sdks-js": () => import("./../../../src/pages/sdks.js" /* webpackChunkName: "component---src-pages-sdks-js" */),
  "component---src-templates-model-js": () => import("./../../../src/templates/model.js" /* webpackChunkName: "component---src-templates-model-js" */),
  "component---src-templates-route-js": () => import("./../../../src/templates/route.js" /* webpackChunkName: "component---src-templates-route-js" */)
}

